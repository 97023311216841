import { getAccessToken } from 'application/contexts/AuthenticationContext';
import { PageContentResponse } from 'application/repositories/pageContentRepository';
import logger from 'helpers/logger';
import { GetServerSideProps, NextPage } from 'next';
import { signIn } from 'next-auth/react';

const Page: NextPage<PageContentResponse> = (pageContent) => {
	// Redirect to auth server
	signIn('authServer', { callbackUrl: '/my-account' });

	return null;
};

export const getServerSideProps: GetServerSideProps<any> = async ({
	req,
	locale,
}) => {
	try {
		const culture = locale === 'default' ? 'en' : locale;
		const accessTokenPromise = getAccessToken(req);
		const accessToken = (await accessTokenPromise) ?? null;

		return {
			props: {
				accessToken,
				culture,
			},
		};
	} catch (error) {
		const { statusCode } = error;
		if (statusCode === 404) {
			return {
				notFound: true,
			};
		}

		logger.error(error);
		throw new Error(error);
	}
};

export default Page;
